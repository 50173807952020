import {
    AttributesI,
    FilteredPublicationsI,
    PublicationFilterI,
    PublicationI,
    StoredPublicationFilterI
} from '../../../models/publication';
import { catchError, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, of, zip } from 'rxjs';
import { FacetsI, HitsDataI } from '../../../models/facets';
import { FiltersInitI, PairKeyLabelI } from '../../../models/filter';
import { LocalStorageAdapter } from '../../../shared/utils/local-storage.adapter';
import { AuthenticateService } from '../../../services/authenticate.service';
import { Configuration } from '../../../constant/configuration';
import { MessageBus } from '../../../messaging/MessageBus';
import { SortType } from '../result/sort-by-display-type/sort-by-display-type.component';
import * as moment from 'moment';
import { DisplayType } from './DisplayType';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as _ from 'lodash';
import { DocumentSimpleSearchI } from '../../../models/documentSearch';
import { FileSaverService } from 'ngx-filesaver';
import { ExportExcelService } from '../../../services/export-excel.service';
import { NavigationEnd, Router } from '@angular/router';
import {
    CACHED_INIT_FILTERS_BY_KEYS,
    CACHED_INIT_FILTERS_KEY,
    InitFiltersRepository,
    skipWhileInitFiltersCached
} from '../../../store';
import { TabPagination } from './tab-pagination';
import { addParamToUrl } from '../../../shared/utils/url-utils';

@Injectable({ providedIn: 'root' })
export class DocumentFilterService {
    get displayType(): DisplayType {
        return this.localStorageAdapter.displayType;
    }

    set displayType(value: DisplayType) {
        this.localStorageAdapter.displayType = value;
    }

    public isSideFilterMenuOpen: boolean;
    public baseFilter: PublicationFilterI = {
        keys: '',
        attributes: { endDateOfApplicability: [false], findInAccessibleOnly: [false] }
    };

    private fireAllHitsAndFirstPageResult$ = new BehaviorSubject<PublicationFilterI>({});

    public scrollTopOnTab: { [key: string]: BehaviorSubject<number> } = {};
    public activeTabKey$ = new BehaviorSubject('ALL');
    public tabsPagination: { [id: string]: TabPagination } = {};
    public facets: FacetsI = {};
    public resultsByTab: { [key: string]: BehaviorSubject<PublicationI[]> } = {};
    public inFetchingResults = false;
    public hitsOnTab: { [key: string]: BehaviorSubject<HitsDataI> } = {};
    public filterAttributesOnTab: { [key: string]: BehaviorSubject<AttributesI> } = {};
    public initFilters: FiltersInitI = { type: new BehaviorSubject<PairKeyLabelI[]>([]) };
    currentUserUuidInitFilters = null;
    private SEARCH_ROUTE_PATH = '/o/document/result';
    private DOCUMENT_DETAIL_ROUTE_PATH = '/o/document/detail';
    private BOOK_DETAIL_ROUTE_PATH = '/o/book/detail';
    private previousResearchRequestFailed = false;
    private serviceUrl = '/webfront/document-filter';

    constructor(
        private localStorageAdapter: LocalStorageAdapter,
        private authenticateService: AuthenticateService,
        private configuration: Configuration,
        private router: Router,
        private fileSaver: FileSaverService,
        private messageBus: MessageBus,
        private exportExcelService: ExportExcelService,
        private httpClient: HttpClient,
        private initFiltersRepository: InitFiltersRepository
    ) {
        // upload sent as null because problems with injection of old ng-js and not needed for this service
        this.initializePublicationFilter();
        configuration.defaultTabTypes.forEach(tabId => {
            this.resultsByTab[tabId] = new BehaviorSubject<PublicationI[]>([]);
            this.filterAttributesOnTab[tabId] = new BehaviorSubject<AttributesI>({});
            this.hitsOnTab[tabId] = new BehaviorSubject<HitsDataI>({});
            this.scrollTopOnTab[tabId] = new BehaviorSubject<number>(0);
            this.filterAttributesOnTab[tabId].subscribe(() => {
                if (tabId === 'ALL') {
                    this.clearTabsContent();
                }
            });
        });
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                if (
                    !event.url.startsWith(this.SEARCH_ROUTE_PATH) &&
                    !event.url.startsWith(this.DOCUMENT_DETAIL_ROUTE_PATH) &&
                    !event.url.startsWith(this.BOOK_DETAIL_ROUTE_PATH)
                ) {
                    // Only removes content if user visits a page that's not part of the search
                    this.baseFilter.keys = '';
                    this.initializePublicationFilter();
                    this.clearTabsContent();
                    this.clearTabsAttributesFilter();
                    this.activeTabKey$.next('ALL');
                }
            }
        });

        // Must get filters in initialisation for first request
        if (this.authenticateService.currentUser) {
            this.getInitialFilters().subscribe();
        }

        // On Login get filters and attributes
        messageBus.channel(configuration.messageBusChannels.USER_LOGIN_CHANNEL).subscribe(() => {
            this.getInitialFilters().subscribe();
        });
        // On logout all information should be removed
        messageBus.channel(configuration.messageBusChannels.USER_LOGOUT_CHANNEL).subscribe(() => {
            this.baseFilter.keys = '';
            this.initializePublicationFilter();
            this.clearTabsAttributesFilter();
            this.clearTabsContent();
        });

        this.fireAllHitsAndFirstPageResult$
            .asObservable()
            .pipe(
                filter(value => value.keys && value.keys.trim().length > 0),
                distinctUntilChanged((previous, current) => {
                    delete previous.pagination;
                    delete current.pagination;
                    return !this.previousResearchRequestFailed && _.isEqual(previous, current);
                }),
                switchMap(value => {
                    this.inFetchingResults = true;
                    const hitsRequests: Observable<HitsDataI>[] = [];
                    (this.initFilters.type as BehaviorSubject<PairKeyLabelI[]>).value.forEach(type => {
                        hitsRequests.push(
                            this.httpClient
                                .post<HitsDataI>(this.serviceUrl + '/hits', this.getSearchFilterByType(type.key), {
                                    headers: new HttpHeaders({
                                        skipSpinner: 'true',
                                        skipTechnicalAndBusinessError: 'true'
                                    })
                                })
                                .pipe(
                                    tap(hits => this.hitsOnTab[type.key].next(hits)),
                                    // ignore errors received from hits requests
                                    catchError(() => of({ hits: null, total: 0 } as HitsDataI))
                                )
                        );
                    });
                    this.clearTabsContent();
                    return zip(
                        ...hitsRequests,
                        this.httpClient
                            .post<DocumentSimpleSearchI>('/webfront/simplesearch', value.keys)
                            .pipe(catchError(() => of({} as DocumentSimpleSearchI))),
                        this.httpClient
                            .post<void>('/webfront/statistics/keyword', value.keys)
                            .pipe(catchError(() => EMPTY))
                    );
                })
            )
            .subscribe(
                () =>
                    void this.search() /* get first page result after getting hits result */
                        .subscribe({
                            next: () => {
                                this.previousResearchRequestFailed = false;
                                this.inFetchingResults = false;
                            },
                            error: () => {
                                this.previousResearchRequestFailed = true;
                                this.inFetchingResults = false;
                            }
                        })
            );
    }

    extractStoredPublicationFilter(currentFilter: PublicationFilterI): StoredPublicationFilterI {
        return {
            attributes: {
                'date-to': currentFilter.attributes['date-to'],
                'date-from': currentFilter.attributes['date-from'],
                language: currentFilter.attributes.language,
                territory: currentFilter.attributes.territory,
                workspace: currentFilter.attributes.workspace,
                findInAccessibleOnly: currentFilter.attributes.findInAccessibleOnly,
                endDateOfApplicability: currentFilter.attributes.endDateOfApplicability,
                lawBranch: currentFilter.attributes.lawBranch,
                lawSubBranch: currentFilter.attributes.lawSubBranch
            }
        };
    }

    initializePublicationFilter(): PublicationFilterI {
        return (this.baseFilter = {
            keys: this.baseFilter !== undefined ? this.baseFilter.keys : '',
            attributes: { endDateOfApplicability: [false], findInAccessibleOnly: [false] }
        });
    }

    clearTabsContent(): void {
        Object.keys(this.resultsByTab).forEach(key => {
            this.resultsByTab[key].next([]);
            this.hitsOnTab[key].next({});
        });
    }

    clearTabsAttributesFilter(): void {
        Object.keys(this.filterAttributesOnTab).forEach(key => {
            this.filterAttributesOnTab[key].next({});
        });
    }

    /**
     * Search for documents on selected tab only if there are no documents in the tab
     */
    async searchOnCurrentTabIfNeeded(): Promise<void> {
        if (this.resultsForCurrentTab === undefined || this.resultsForCurrentTab.value.length === 0) {
            this.search().subscribe();
        }
    }

    search(): Observable<FilteredPublicationsI> {
        this.preserveCurrentFilterInUrl();
        this.resultsForCurrentTab.next([]);
        return this.firstPage();
    }

    preserveCurrentFilterInUrl(): void {
        // update url to reflect current filter
        const concreteFilter = { ...this.currentFilter };
        delete concreteFilter.pagination;
        addParamToUrl('filter', JSON.stringify(concreteFilter));
    }

    public fetchSearchResultsFromServer(searchFilter: PublicationFilterI): Observable<FilteredPublicationsI> {
        this.inFetchingResults = true;
        return this.httpClient
            .post<FilteredPublicationsI>(this.serviceUrl + '/search', searchFilter, {
                headers: new HttpHeaders({ skipSpinner: 'true' })
            })
            .pipe(tap(() => (this.inFetchingResults = false)));
    }

    get currentFilter(): PublicationFilterI {
        const newFilter = JSON.parse(JSON.stringify(this.baseFilter)) as PublicationFilterI;
        const allTabAttributes = this.attributesForTab('ALL');
        newFilter.attributes = {
            ...this.baseFilter.attributes,
            ...allTabAttributes,
            ...this.attributesForTab(this.activeTabKey$.value)
        };
        // these two params are set on global filters
        newFilter.attributes.findInAccessibleOnly = allTabAttributes.findInAccessibleOnly || [false];
        newFilter.attributes.endDateOfApplicability = allTabAttributes.endDateOfApplicability || [false];
        if (this.activeTabKey$.value !== `ALL`) {
            newFilter.attributes.type = [this.activeTabKey$.value];
        }
        return newFilter;
    }

    get currentSortType(): SortType {
        return this.sortTypes.find(type => type.value === this.getCurrentPagination().pagination.sort);
    }

    get sortTypes(): SortType[] {
        return this.configuration.sortTypes;
    }

    get hitsOnCurrentTab(): HitsDataI {
        return this.hitsOnTab[this.activeTabKey$.value].value;
    }

    /**
     * To find the real key in the facets and the filter attributes of the current dropdown category
     * Method that should be updated when a category name does not match the facet key
     */
    getCategoryKey(filterKey: string): string {
        switch (filterKey) {
            case 'magazine':
                return 'source';
            case 'publisher':
                return 'partner';
            case 'book':
                return 'source';
            case 'author':
                return 'authorId';
            default:
                return filterKey;
        }
    }

    attributesForTab(tabKey: string): AttributesI {
        return this.filterAttributesOnTab[tabKey] !== undefined
            ? (JSON.parse(JSON.stringify(this.filterAttributesOnTab[tabKey].value)) as AttributesI)
            : {};
    }

    /**
     * Updates all hits of all tabs based on the filter each of them have selected and the global ones
     */
    updateAllHitsAndGetFirstPageResult(): void {
        this.fireAllHitsAndFirstPageResult$.next(this.getSearchFilterByType('ALL'));
    }

    getSearchFilterByType(key: string): PublicationFilterI {
        if (this.router.url.startsWith(this.SEARCH_ROUTE_PATH)) {
            const attributesForAll = this.attributesForTab('ALL');
            let attributesForTab = { ...attributesForAll };
            if (key !== 'ALL') {
                attributesForTab = {
                    ...attributesForTab,
                    ...this.attributesForTab(key),
                    type: [key]
                };
            }
            // Recover the overridden global attributes
            attributesForTab.findInAccessibleOnly = attributesForAll.findInAccessibleOnly || [false];
            attributesForTab.endDateOfApplicability = attributesForAll.endDateOfApplicability || [false];
            // Get the current filter and locally replace the attributes
            const searchFilter = JSON.parse(JSON.stringify(this.baseFilter)) as PublicationFilterI;
            searchFilter.attributes = attributesForTab;
            return searchFilter;
        }
        return {} as PublicationFilterI;
    }

    getInitialFilters(): Observable<FiltersInitI> {
        return this.httpClient
            .get<FiltersInitI>(this.serviceUrl + '/init-filters', {
                headers: new HttpHeaders({ skipSpinner: 'true' })
            })
            .pipe(
                tap(initFilters =>
                    this.initFiltersRepository.upsertInitFilters(
                        this.authenticateService.currentUser?.uuid
                            ? this.authenticateService.currentUser?.uuid
                            : 'anonymous-user',
                        initFilters
                    )
                ),
                skipWhileInitFiltersCached(
                    `${CACHED_INIT_FILTERS_KEY}-${
                        this.authenticateService.currentUser?.uuid
                            ? this.authenticateService.currentUser?.uuid
                            : 'anonymous-user'
                    }`,
                    {
                        returnSource: this.initFiltersRepository.selectInitFilters$(
                            this.authenticateService.currentUser?.uuid
                                ? this.authenticateService.currentUser?.uuid
                                : 'anonymous-user'
                        )
                    }
                )
            )
            .pipe(
                tap(result => {
                    if (this.currentUserUuidInitFilters !== this.authenticateService.currentUser.uuid) {
                        // type observable is created in constructor and should keep pointer to it
                        this.initFilters = { ...result, type: this.initFilters.type };
                        const types = [
                            { key: 'ALL', label: 'ALL' },
                            ...(result.type as PairKeyLabelI[]).filter(type => {
                                // we do not show "publications" tab and document type should be on the available markets of the user
                                return type.key !== this.configuration.documentTabTypes.PUBLICATION;
                            })
                        ];
                        (this.initFilters.type as BehaviorSubject<PairKeyLabelI[]>).next(types);
                        this.currentUserUuidInitFilters = this.authenticateService.currentUser.uuid;
                    }
                })
            );
    }

    get anyDocuments(): boolean {
        return this.resultsForCurrentTab.value.length > 0;
    }

    get resultsForCurrentTab(): BehaviorSubject<PublicationI[]> {
        if (!this.resultsByTab[this.activeTabKey$.value]) {
            this.resultsByTab[this.activeTabKey$.value] = new BehaviorSubject<PublicationI[]>([]);
        }
        return this.resultsByTab[this.activeTabKey$.value];
    }

    updateFilterKeys(keys: string): void {
        this.baseFilter.keys = keys;
    }

    saveFiltersAsDefault(filters: PublicationFilterI | null): void {
        this.httpClient
            .post<void>('/webfront/default-filters', filters !== null ? JSON.stringify(filters) : undefined)
            .subscribe();
    }

    getCurrentPagination(): TabPagination {
        if (this.tabsPagination[this.activeTabKey$.value] == null) {
            this.tabsPagination[this.activeTabKey$.value] = new TabPagination(this);
        }
        return this.tabsPagination[this.activeTabKey$.value];
    }

    firstPage(): Observable<FilteredPublicationsI> {
        return this.getCurrentPagination().firstPage(this.currentFilter);
    }

    nextPage(): Observable<FilteredPublicationsI> {
        return this.getCurrentPagination().nextPage(this.currentFilter);
    }

    previousPage(): Observable<FilteredPublicationsI> {
        return this.getCurrentPagination().previousPage(this.currentFilter);
    }

    exportSearch(): void {
        const currentFilter = this.currentFilter;
        currentFilter.totalItems = this.hitsOnCurrentTab.total;
        this.httpClient
            .post<string>(`${this.serviceUrl}/filter.csv`, { ...currentFilter }, { responseType: 'text' as 'json' })
            .subscribe(response => {
                const data = new Blob([response], { type: 'application/csv' });
                this.fileSaver.save(data, moment().toISOString() + '-filter.csv');
            });
    }

    exportSearchResultsReport(): Observable<ArrayBuffer> {
        const currentFilter = this.currentFilter;
        currentFilter.totalItems = this.hitsOnCurrentTab.total;
        return this.exportExcelService.fetchExcelFilterReportAndDownload(
            this.serviceUrl,
            currentFilter,
            moment().toISOString() + '-filter.xlsx'
        );
    }

    getInitialFiltersByKeys(keys: string[]): Observable<FiltersInitI> {
        const formattedKey = keys.join(',');

        return this.httpClient
            .get<FiltersInitI>(this.serviceUrl + '/init-filters-by-keys', {
                headers: { skipSpinner: 'true' },
                params: { keys: formattedKey }
            })
            .pipe(
                map(initFilters => {
                    const typeFiltered = initFilters.type
                        ? (initFilters.type as PairKeyLabelI[]).filter(
                              // we do not show "publications" tab
                              (type: PairKeyLabelI) => type.key !== this.configuration.documentTabTypes.PUBLICATION
                          )
                        : initFilters.type;

                    const formattedInitFilters = {
                        ...initFilters,
                        type: typeFiltered
                    };

                    this.initFiltersRepository.upsertInitFiltersByKeys(
                        this.authenticateService.currentUser?.uuid || 'anonymous-user',
                        formattedKey,
                        formattedInitFilters
                    );
                    return formattedInitFilters;
                }),
                skipWhileInitFiltersCached(
                    `${CACHED_INIT_FILTERS_BY_KEYS}-${
                        this.authenticateService.currentUser?.uuid || 'anonymous-user'
                    }-${formattedKey}`,
                    {
                        returnSource: this.initFiltersRepository.selectInitFiltersByKeys$(
                            this.authenticateService.currentUser?.uuid || 'anonymous-user',
                            formattedKey
                        )
                    }
                )
            );
    }

    getAssetOfDocumentType(documentTypeLabel: string): { color: string; thumbnail: string } {
        const thumbnailPath = 'images/thumbnails/';
        switch (documentTypeLabel) {
            case 'Legislation':
            case 'LEG':
                return { color: 'red', thumbnail: thumbnailPath + 'thumbnail-legislation.jpg' };
            case 'Jurisprudence':
            case 'JUR':
                return { color: 'violet', thumbnail: thumbnailPath + 'thumbnail-law.jpeg' };
            case 'Dossier Parlementaire':
            case 'PAR':
                return { color: 'green', thumbnail: thumbnailPath + 'thumbnail-parliament.jpeg' };
            case 'Circulaire Administrative':
            case 'CIR':
                return { color: 'blue', thumbnail: thumbnailPath + 'thumbnail-administrative.jpg' };
            case 'Doctrine':
            case 'DOC':
                return { color: 'purple', thumbnail: thumbnailPath + 'thumbnail-doctrine.jpeg' };
            case 'Publication':
                return { color: 'orange', thumbnail: thumbnailPath + 'thumbnail-publication.jpeg' };
            case 'Bonne Pratique':
            case 'GUI':
                return { color: 'brown', thumbnail: thumbnailPath + 'thumbnail-guidelines.jpeg' };
            default:
                return { color: 'black', thumbnail: thumbnailPath + 'thumbnail-unknown.jpg' };
        }
    }
}
