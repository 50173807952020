import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-search-input',
    templateUrl: './search-input.component.html',
    styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent {
    @Input() loading = false;
    @Input() search = '';
    @Input() placeholder = 'global.action.search';
    @Output() searchChange = new EventEmitter<string>();
    @Output() clearSearchEvent = new EventEmitter<void>();

    onSearchChange(): void {
        this.searchChange.emit(this.search);
    }

    clearSearch(): void {
        this.search = '';
        this.onSearchChange();
        this.clearSearchEvent.emit();
    }
}
