import { createStore } from '@ngneat/elf';
import { getEntity, selectEntity, upsertEntitiesById, withEntities } from '@ngneat/elf-entities';
import { createRequestsCacheOperator, updateRequestCache, withRequestsCache } from '@ngneat/elf-requests';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { FolderI } from '../models/folder';

interface FolderStateI {
    userUuid: string;
    foldersFiltered: Array<{
        filter: string;
        folders: FolderI[];
    }>;
}

const store = createStore(
    { name: 'folders' },
    withEntities<FolderStateI, 'userUuid'>({ idKey: 'userUuid' }),
    withRequestsCache<`folders-${string}`>()
);
export const skipWhileFoldersCached = createRequestsCacheOperator(store);
export const CACHE_KEY_FOLDER = 'folders';

@Injectable({ providedIn: 'root' })
export class FolderRepository {
    selectFoldersByUserUuidAndFilter$(userUuid: string, filter: string): Observable<FolderI[]> {
        return store.pipe(selectEntity(userUuid, { pluck: 'foldersFiltered' })).pipe(
            map(foldersFiltered => {
                const correctFoldersFiltered = foldersFiltered.find(f => f.filter === filter);
                return correctFoldersFiltered ? correctFoldersFiltered.folders : [];
            })
        );
    }

    upsertFoldersByUserUuid(userUuid: string, filter: string, folders: FolderI[]): void {
        store.update(
            updateRequestCache(`${CACHE_KEY_FOLDER}-${userUuid}-${filter}`),
            upsertEntitiesById(userUuid, {
                creator: () => ({ userUuid, foldersFiltered: [{ filter, folders }] }),
                updater: entity => {
                    const correctFoldersFilteredIndex = entity.foldersFiltered.findIndex(f => f.filter === filter);
                    if (correctFoldersFilteredIndex !== -1) {
                        entity.foldersFiltered[correctFoldersFilteredIndex].folders = folders;
                    } else {
                        entity.foldersFiltered.push({ filter, folders });
                    }
                    return { ...entity };
                }
            })
        );
    }

    clearCacheOfUser(userUuid: string): void {
        const foldersOfUser = store.query(getEntity(userUuid));
        if (foldersOfUser) {
            foldersOfUser.foldersFiltered.forEach(foldersFiltered => {
                store.update(
                    updateRequestCache(`${CACHE_KEY_FOLDER}-${userUuid}-${foldersFiltered.filter}`, { value: 'none' })
                );
            });
        }
    }
}
