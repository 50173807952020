import { Inject, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertsComponent } from '../../shared/components/alerts/alerts.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialModule } from '../angular-material/angular-material.module';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
    faArrowRightFromBracket,
    faBarChart,
    faBooks,
    faChevronDown,
    faFolders,
    faHandshake,
    faHeadSideBrain,
    faMagnifyingGlass,
    faSliders,
    faStar
} from '@fortawesome/pro-solid-svg-icons';
import { RouterModule } from '@angular/router';
import { GuardedRouterLinkDirective } from 'src/app/shared/directive/guarded-router-link.directive';
import { RouteNameHeaderComponent } from '../../components/route-name-header/route-name-header.component';
import { DropdownFilterComponent } from '../../online/document/filters/dropdown-filter/dropdown-filter.component';
import { CollapsibleCheckboxTreeDropdownComponent } from '../../components/collapsible-checkbox-tree-dropdown/collapsible-checkbox-tree-dropdown.component';
import { SortByFilterComponent } from 'src/app/online/library/library-filters/sortby/sort-by-filter.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FiltersSearchInputComponent } from '../../online/document/filters/search-input/filters-search-input.component';
import { CollapsibleCheckboxTreeComponent } from 'src/app/components/collapsible-checkbox-tree/collapsible-checkbox-tree.component';
import { SortCollapsibleElementsPipe } from '../../components/collapsible-checkbox-tree/sort-collapsible-elements.pipe';
import { FiltersToggleComponent } from 'src/app/components/toggle/filters-toggle.component';
import { SortFilterFieldsPipe } from '../../online/document/result/pipes/sort-filter-fields.pipe';
import { FilterFieldsPipe } from '../../online/document/result/pipes/filter-fields.pipe';
import { AutocompleteAuthorInputComponent } from '../../online/document/filters/autocomplete-author-input/autocomplete-author-input.component';
import { IndeterminateLoadingBarComponent } from '../../components/indeterminate-loading-bar/indeterminate-loading-bar.component';
import { ThreeStateCheckboxComponent } from '../../components/three-state-checkbox/three-state-checkbox.component';
import { SearchIconComponent } from '../../online/search/search-bar/search-icon/search-icon.component';
import { SortPipe } from '../../filter/sort.pipe';
import { AppDatepickerRangeComponent } from '../../shared/components/app-datepicker-range/app.datepicker.range';
import { LocalizedDatePipe } from '../../shared/pipes/localized-date.pipe.js';
import { FormatHitsPipe } from '../../online/document/result/pipes/format-hits.pipe';
import { FacetsHitsPipe } from '../../online/document/result/pipes/facets-hits.pipe';
import { LabelTruncatorFilter } from '../../filter/label-truncator.filter';
import { AuthorsToOneLinePipe } from '../../filter/authors-to-one-line.pipe';
import { SwitchComponent } from '../../shared/components/switch.component';
import { FieldErrorsDisplayComponent } from 'src/app/shared/components/field-errors-display/field-errors-display.component';
import { BackInHistoryButtonComponent } from '../../shared/components/back-in-history-button/back-in-history-button.component';
import { FilterCustomFieldsPipe } from '../../filter/filter-custom-fields.pipe';
import { InnerHtmlMutationObserverDirective } from 'src/app/shared/directive/inner-html-mutation-observer.directive';
import { LocalDate } from '../../filter/local-date.filter';
import { SortAlphanumericPipe } from 'src/app/filter/sort-alphanumeric.pipe';
import { PaginationComponent } from '../../components/pagination/pagination.component';
import { SortFieldComponent } from '../../shared/components/sort-field/sort-field.component';
import { LocalDateTime } from '../../filter/local-date-time.filter';
import { ParseRomanPipe } from '../../filter/parse-roman.pipe';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { PartnersToOneLinePipe } from '../../filter/partners-to-one-line.pipe';
import { UnauthorizedComponent } from '../../online/unauthorized/unauthorized.component';
import { MigratedCollapsibleCheckboxTreeDropdownComponent } from 'src/app/components/migrated-collapsible-checkbox-tree-dropdown/migrated-collapsible-checkbox-tree-dropdown.component';
import { MigratedTypeAheadDropdownComponent } from 'src/app/components/migrated-type-ahead-dropdown/migrated-type-ahead-dropdown.component';
import { MigratedFilterDateRangeSelectorComponent } from '../../online/document/filters/migrated-filter-date-range-selector/migrated-filter-date-range-selector.component';
import { DotLoaderComponent } from '../../components/dot-loader/dot-loader.component';
import { CopyToClipboardButtonComponent } from '../../components/copy-to-clipboard-button/copy-to-clipboard-button.component';
import { AutocompleteInputComponent } from '../../components/autocomplete-input/autocomplete-input.component';
import { SearchInputComponent } from '../../components/search-input/search-input.component';
import { SortDocumentTypeByPriorityPipe } from '../../filter/sort-document-type-by-priority.pipe';

@NgModule({
    providers: [SortDocumentTypeByPriorityPipe],
    imports: [
        CommonModule,
        TranslateModule,
        AngularMaterialModule,
        FontAwesomeModule,
        RouterModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule
    ],
    declarations: [
        // DIRECTIVES
        GuardedRouterLinkDirective,
        InnerHtmlMutationObserverDirective,
        // PIPE
        SortCollapsibleElementsPipe,
        SortFilterFieldsPipe,
        FilterFieldsPipe,
        SortPipe,
        LocalizedDatePipe,
        FormatHitsPipe,
        FacetsHitsPipe,
        LabelTruncatorFilter,
        AuthorsToOneLinePipe,
        PartnersToOneLinePipe,
        FilterCustomFieldsPipe,
        LocalDate,
        SortAlphanumericPipe,
        LocalDateTime,
        ParseRomanPipe,
        SortDocumentTypeByPriorityPipe,
        // COMPONENTS
        AlertsComponent,
        RouteNameHeaderComponent,
        DropdownFilterComponent,
        CollapsibleCheckboxTreeDropdownComponent,
        SortByFilterComponent,
        FiltersSearchInputComponent,
        CollapsibleCheckboxTreeComponent,
        FiltersToggleComponent,
        AutocompleteAuthorInputComponent,
        IndeterminateLoadingBarComponent,
        ThreeStateCheckboxComponent,
        SearchIconComponent,
        AppDatepickerRangeComponent,
        SwitchComponent,
        FieldErrorsDisplayComponent,
        BackInHistoryButtonComponent,
        PaginationComponent,
        SortFieldComponent,
        UnauthorizedComponent,
        MigratedCollapsibleCheckboxTreeDropdownComponent,
        MigratedTypeAheadDropdownComponent,
        MigratedFilterDateRangeSelectorComponent,
        DotLoaderComponent,
        CopyToClipboardButtonComponent,
        AutocompleteInputComponent,
        SearchInputComponent
    ],
    exports: [
        // MODULES
        FormsModule,
        TranslateModule,
        AngularMaterialModule,
        FontAwesomeModule,
        RouterModule,
        NgbModule,
        ReactiveFormsModule,
        // PIPE
        SortPipe,
        LocalizedDatePipe,
        FormatHitsPipe,
        FacetsHitsPipe,
        LabelTruncatorFilter,
        AuthorsToOneLinePipe,
        PartnersToOneLinePipe,
        FilterCustomFieldsPipe,
        LocalDate,
        SortAlphanumericPipe,
        LocalDateTime,
        ParseRomanPipe,
        SortDocumentTypeByPriorityPipe,
        // DIRECTIVES
        GuardedRouterLinkDirective,
        InnerHtmlMutationObserverDirective,
        // COMPONENTS
        RouteNameHeaderComponent,
        DropdownFilterComponent,
        CollapsibleCheckboxTreeDropdownComponent,
        SortByFilterComponent,
        AlertsComponent,
        CollapsibleCheckboxTreeComponent,
        FiltersToggleComponent,
        IndeterminateLoadingBarComponent,
        ThreeStateCheckboxComponent,
        SearchIconComponent,
        AppDatepickerRangeComponent,
        SwitchComponent,
        FieldErrorsDisplayComponent,
        BackInHistoryButtonComponent,
        PaginationComponent,
        SortFieldComponent,
        UnauthorizedComponent,
        MigratedCollapsibleCheckboxTreeDropdownComponent,
        MigratedTypeAheadDropdownComponent,
        AutocompleteInputComponent,
        MigratedFilterDateRangeSelectorComponent,
        DotLoaderComponent,
        CopyToClipboardButtonComponent,
        SearchInputComponent
    ]
})
export class CommonSharedModule {
    constructor(
        library: FaIconLibrary,
        private dateAdapter: DateAdapter<Date>,
        @Inject(MAT_DATE_LOCALE) private _locale: string,
        private translate: TranslateService
    ) {
        library.addIcons(
            faBooks,
            faMagnifyingGlass,
            faHeadSideBrain,
            faStar,
            faSliders,
            faArrowRightFromBracket,
            faChevronDown,
            faHandshake,
            faBarChart,
            faFolders
        );

        this.setMaterialLocale(this.translate.currentLang);
        this.translate.onLangChange.subscribe(data => {
            this.setMaterialLocale(data.lang);
        });
    }

    setMaterialLocale(lang: string): void {
        if (lang) {
            this._locale = lang;
            this.dateAdapter.setLocale(this._locale);
        }
    }
}
