import { Pipe, PipeTransform } from '@angular/core';
import { PairKeyLabelI } from '../models/filter';
import { MarketE } from '../models/market';
import { Configuration } from '../constant/configuration';

@Pipe({
    name: 'sortDocumentTypeByPriority'
})
export class SortDocumentTypeByPriorityPipe implements PipeTransform {
    transform(value: PairKeyLabelI[], markets: number[]): PairKeyLabelI[] {
        if (markets.includes(MarketE.BE) || markets.includes(MarketE.LU)) {
            const configuration = new Configuration();
            const weightKey = markets.includes(MarketE.BE) ? 'beWeight' : 'luWeight';

            return value.sort((a, b) => {
                const aDocumentType = configuration.documentTypesWithWeightByMarket.find(x => x.key === a.key);
                const aWeight = aDocumentType ? aDocumentType[weightKey] : null;

                const bDocumentType = configuration.documentTypesWithWeightByMarket.find(x => x.key === b.key);
                const bWeight = bDocumentType ? bDocumentType[weightKey] : null;

                if (aWeight === bWeight) {
                    return a.label.localeCompare(b.label);
                }
                if (!aWeight && aWeight !== 0 && bWeight) {
                    return 1;
                }
                if (aWeight && !bWeight && bWeight !== 0) {
                    return -1;
                }
                return aWeight - bWeight;
            });
        }
        return value;
    }
}
